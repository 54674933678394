import * as React from "react"
import styled from "styled-components"
import { themeGet } from "styled-system"
import { AboutPageTitle, AboutWrapper, AboutDetails } from "../About/style"

const Contact: React.SFC<{}> = () => {
  return (
    <AboutWrapper>
      <AboutPageTitle>
        <h2>Contact Me</h2>
        <p>Please feel free to get in touch with me! For any inquiries please use my email address listed below. Thanks!</p>
      </AboutPageTitle>
      <AboutDetails>
        <Email>
          <a href="mailto:courtney@lovelyheartlife.com">
            courtney@lovelyheartlife.com
          </a>
        </Email>
      </AboutDetails>
    </AboutWrapper>
  )
}

const Email = styled.div`
  margin-top: 16px;
  a {
    margin: 0 auto;
    font-size: 13px;
    font-weight: 500;
    color: ${themeGet("textColor", "#292929")};
    transition: 0.15s ease-in-out;
    &:hover {
      color: ${themeGet("primary", "#f6a794")};
    }
  }
`

export default Contact
