import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../containers/Contact"

type ContactPageProps = {}

const ContactPage: React.FunctionComponent<ContactPageProps> = props => {
  return (
    <Layout>
      <SEO
        title="Contact Us"
        description="
        I created Lovely Heart Life originally as a blog to keep track of fun family memories, but it has quickly evolved into something even more special. I have always loved writing and I love that I now have an outlet for that passion. Now you will find other content such as educational activities for your littles and kid friendly recipes that me and my family love! 
        Lovely Heart Life is more than a blog, it’s also a shop! Coming in November, you will be able to purchase the Lovely Heart Learning Curriculum. This curriculum is for children ages 2-5 and will be available each month with a new theme and letter to learn! I am so excited about this project. I graduated with a Bachelor of Science in Elementary Education with an emphasis on Early Childhood Education and one of my favorite parts of school and my student teaching was creating and implementing lesson plans. If you’ve been wanting to start doing more educational projects and activities with your child, then you will want to purchase this! Every month will have printable activities, fun recipes, and full lesson plans written out for you. It’s all about learning through play! 
        I can’t wait to share all of this fun and special content with you all!"
      />

      <Contact />
    </Layout>
  )
}

export default ContactPage
